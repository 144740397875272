import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/prufungssituationen"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/durchfallmythen/" title>
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/richtig-essen/" title>
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded active-trail">
                        <a
                          href="/stress-durchfall/"
                          title
                          className="active-trail"
                        >
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf active-trail">
                            <a
                              href="/prufungssituationen/"
                              title
                              className="active-trail active"
                            >
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>*/}
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*<div className="region region-sidebar">
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/imodium-info-box-grafik_0.gif) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{ fontWeight: "bold", fontSize: "30px", lineHeight: "26px", margin: "20px 0 10px 0", fontFamily: "'Open Sans', sans-serif" }}>
                        DIAGNOSE
                        <br />
                        REIZDARM
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_stress_durchfall.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Etwa jeder fünfte Erwachsene leidet irgendwann im Leben
                        an Reizdarm-Symptomen. Finden Sie hier Informationen zu
                        Ursachen und Behandlung.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/diagnose-reizdarm/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>*/}
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Prüfungssituationen{" "}
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="flash_container">
                      <div className="field field-name-field-teaser-stuff field-type-text-long field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              <img
                                alt
                                src="/sites/default/files/header_visuals_695x286_pr%C3%BCfung.jpg"
                                style={{
                                  width: "695px",
                                  height: "286px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="std-site-alternative">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span style={{ top: "27px" }}>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even" style={{ alignSelf: "center", alignContent: "center" }}>
                                      Was können Sie tun?
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            In den verschiedenen Lebensphasen sind wir immer
                            wieder Prüfungsstress ausgesetzt. Matura,
                            Führerschein oder Bewerbungsgespräche sind mit
                            Aufregung und Stress verbundene Ereignisse, die bei
                            vielen Unruhe, Schlaflosigkeit und auch Durchfall
                            hervorrufen können.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <p>
                                Die Erscheinung, dass die Verdauung bei
                                Prüfungssituationen verrückt spielt, ist auch
                                unter der Bezeichnung PAD (Performance Anxiety
                                Diarrhoea) oder Durchfall durch Versagensangst
                                bekannt. Das bedeutet, dass Durchfall dann
                                auftritt, wenn Sie unter Leistungsdruck stehen,
                                z.B. in Prüfungssituationen, bei Wettbewerben,
                                Vorträgen oder Vorstellungsgesprächen.
                              </p>
                              <p>
                                Wenn auch Ihr Körper auf Stress und Anspannung
                                mit Durchfall reagiert, können folgende Tipps
                                helfen, in kritischen Situationen die Ruhe zu
                                bewahren und den Prüfungsstress besser in den
                                Griff zu bekommen:
                              </p>
                              <ul>
                                <li>
                                  <strong>
                                    Bereiten Sie sich gründlich vor
                                  </strong>
                                  <br />
                                  Versuchen Sie, einer Prüfungssituation oder
                                  einem Vorstellungsgespräch mit Zuversicht zu
                                  begegnen. Dazu ist es wichtig, dass Sie sich
                                  ausreichend Zeit nehmen, um sich inhaltlich
                                  gründlich auf das Ereignis vorzubereiten. Bei
                                  Vorträgen oder Vorstellungsgesprächen ist es
                                  hilfreich, Ihre Aufzeichnungen und mögliche
                                  Antworten mit Dritten durchzusprechen.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>Atmen Sie durch</strong>
                                  <br />
                                  Eine tiefe und langsame Atmung macht sie
                                  automatisch ruhiger. Auch das Erlernen von
                                  Meditationstechniken kann Ihnen helfen, sich
                                  in Gelassenheit zu üben.
                                </li>
                              </ul>
                              <ul>
                              <li>
                                  <strong>
                                    Nehmen Sie entspannende Nahrung zu sich{" "}
                                  </strong>
                                  <br />
                                  Nahrungsmittel, die viel Vitamin-B enthalten,
                                  können entspannend wirken. Daher sollte Ihr
                                  Speiseplan Vollkornprodukte -&nbsp; wie
                                  Vollkorn-Sandwiches oder Hafersnacks - und
                                  grünes Blattgemüse enthalten. Rezepte, die zu einer ausgewogenen Ernährung beitragen können, finden Sie auf unserer{" "}
                                  <a href="/rezepte/">Rezeptseite</a>.
                               </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>Bewegen Sie sich </strong>
                                  <br />
                                  Mit Hilfe von körperlicher Aktivität gelingt
                                  es Ihnen leichter, Stress abzubauen. Bewegen
                                  Sie sich daher auch in Phasen, z.B. in
                                  Lernphasen vor Prüfungen, in denen es die Zeit
                                  scheinbar nicht zulässt.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
